<template>
    <div v-loading="loading">
        <div class="card card-custom card-stretch gutter-b">
            <div class="card-header">
                <div class="card-title">
                    <span class="card-icon">
                        <i class="flaticon2-delivery-package text-primary"></i>
                    </span>
                    <h3 class="card-label"><b class="title-header">フォーム作成</b></h3>
                </div>
            </div>
            <div class="card-body">
                <el-form ref="ruleForm" :model="ruleForm" label-position="left" label-width="40%"
                         class="demo-ruleForm  form-base" >
                    <div class="row mb-8">
                        <div class="col-12 col-md-10 col-xl-5">
                            <label class="el-form-item__label" for="">フォーム名 <span
                                class="text-danger">*</span></label>
                            <el-form-item label="" prop="form_name" class="item-title-form item-fome-base">
                                <el-input maxlength="40" class="bg-gray-item" placeholder="フォーム名を入力" @keyup.enter.native="isSubmit=true"
                                          v-model="ruleForm.form_name"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label" for="">フォームURL <span
                                class="text-danger">*</span></label>
                            <el-form-item label=" " prop="form_url" class="item-title-form item-fome-base">
                                <el-input maxlength="300" class="bg-gray-item"  @keyup.enter.native="isSubmit=true" placeholder="フォームURLを入力"
                                          v-model="ruleForm.form_url"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label" for="">項目グループ名 <span
                                class="text-danger">*</span></label>
                            <el-form-item class="item-title-form item-fome-base" label=" "
                                          prop="item_group_names">
                                <el-input maxlength="1000" class="bg-gray-item" placeholder="項目グループ名を入力" type="textarea"
                                          v-model="ruleForm.item_group_names"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label" for="">項目グループ名（中）</label>
                            <el-form-item class="item-title-form item-fome-base" label=" "
                                          prop="item_group_name_middle">
                                <el-input class="bg-gray-item" type="textarea" placeholder="項目グループ名（中）を入力"
                                          v-model="ruleForm.item_group_name_middle"></el-input>
                            </el-form-item>
                        </div>
                    </div>
<!--                    phase 2-->
                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label">親フォーム名</label>
                            <el-form-item class="item-title-form item-fome-base font-label-custom" label="" prop="parent_form_id">
                                <el-select class="w--10 bg-gray-item" v-model="ruleForm.parent_form_id" placeholder="選択してください">
                                    <el-option label="なし" value=" "></el-option>
                                    <el-option
                                        v-for="(item, index) in form_base_select"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div v-if="currentUser.super_user" class="row mb-8">
                        <div class="col-12 col-md-10 col-xl-5">
                            <label class="el-form-item__label" for=""> テナント</label>
                            <el-form-item class="item-title-form item-fome-base" label=""
                                          prop="tenant_code">
                                <el-select @change="changeTenant()" class="w-100 bg-gray-item"  v-model="ruleForm.tenant_code" placeholder="選択してください">
                                    <el-option label="なし" value=" "></el-option>
                                    <el-option
                                        v-for="(item, index) in listTenant"
                                        :key="index"
                                        :label="item.tenant_name"
                                        :value="item.tenant_code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label">適用承認プロセス名</label>
                            <el-form-item class="item-title-form item-fome-base " label="" prop="approval_id">
                                <el-select @change="changeApproval()" class="w--10 bg-gray-item" v-model="ruleForm.approval_id" placeholder="選択してください">
                                    <el-option label="なし" value=" "></el-option>
                                    <el-option
                                        v-for="(item, index) in list_approval"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label"></label>
                            <el-form-item class="item-title-form item-fome-base " label="" prop="display_new_account">
                                <el-checkbox v-model="ruleForm.display_new_account">新規入力</el-checkbox>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label"></label>
                            <div class="henshu-form-base">
                            <el-form-item class="item-title-form item-fome-base henshu-form-child" label="" prop="copy_flag">
                                <el-checkbox :disabled="!ruleForm.parent_form_id || ruleForm.parent_form_id === ' '" v-model="ruleForm.copy_flag">回答時に回答項目を親フォームからコピー</el-checkbox>
                            </el-form-item>
                            <el-form-item class="item-title-form item-fome-base " label="" prop="henshu_flg">
                                <el-checkbox v-model="ruleForm.henshu_flg">申請者が入力完了後にも情報修正を可能</el-checkbox>
                            </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label"></label>
                            <el-form-item class="item-title-form item-fome-base " label="" prop="copy_group_name">
                                <el-checkbox v-model="ruleForm.copy_group_name" :disabled="ruleForm.special_item">回答時に項目グループ単位での解答欄複製を許可する</el-checkbox>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label"></label>
                            <el-form-item class="item-title-form item-fome-base " label="" prop="copy_group_name_middle">
                                <el-checkbox v-model="ruleForm.copy_group_name_middle" :disabled="ruleForm.special_item">回答時に項目グループ（中）単位での解答欄複製を許可する</el-checkbox>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label"></label>
                            <el-form-item class="item-title-form item-fome-base " label="" prop="kaiso_flg">
                                <el-checkbox :disabled="disabled_check" v-model="ruleForm.kaiso_flg"> 階層フォームとする</el-checkbox>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label"></label>
                            <el-form-item class="item-title-form item-fome-base " label="" prop="tenant_copy_flg">
                                <el-checkbox :disabled="disabled_check" v-model="ruleForm.tenant_copy_flg">子テナントにコピーする</el-checkbox>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label"></label>
                            <el-form-item class="item-title-form item-fome-base " label="" prop="special_item">
                                <el-checkbox :disabled="ruleForm.copy_group_name || ruleForm.copy_group_name_middle" v-model="ruleForm.special_item">条件分岐・自動計算の機能</el-checkbox>
                            </el-form-item>
                        </div>
                    </div>
<!--                    phase 2-->
                    <el-form-item prop="action" class="button-search" label-width="0px">
                        <el-button class="ml-md-40 pl-10 pr-10" type="primary" @click="isSubmit=true">入力完了</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="submit-form-item">
                <Dialog v-on:closeDialog="isSubmit=false" v-on:submit="create" v-bind:dialog="isSubmit"
                        :closeOnClickModal="false" width="28%" btnOk="はい" :showCancelButton="true" btnCancel="いいえ">
                    <template slot="header">
                        <div class="modal-header text-center">
                            <h4 style="width: 100%;">確認</h4>
                        </div>
                    </template>
                    <template slot="content">
                        <div class="textwidget">
                            <p>{{ $t('FORM.SETTING.FORM_ITEM_CONFIRM_SUBMIT', {form_name: ruleForm.form_name}) }}</p>
                        </div>
                    </template>
                </Dialog>
            </div>
            <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                       :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
                <template slot="header">
                    <div class="textwidget text-center">
                        {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
                    </div>
                </template>
            </my-dialog>
        </div>
    </div>
</template>


<style lang="scss">
.card-custom {
    border-radius: 15px;
}

.card-custom > .card-header {
    padding: 0;
    margin: 0px 21px;
}
.w--10 {
    width: 100%;
    font: initial !important;
}
.font-label-custom {
    font-weight: bold;
    font-size: 16px;
}
.el-dialog__header{
    padding: 0;
}
</style>

<script>
import apiService from "@/core/services/api.service";
import * as constants from "@/core/config/constant";
import Dialog from "@/view/components/Dialog";
import {CREATE_FORM_BASE,GET_ALL_LIST_FORM_BASE} from "@/core/services/store/formStore";
import {GET_LIST_APPROVAL_ROLE} from "@/core/services/store/approvalRole.module";
import moment from 'moment';
import {mapActions, mapGetters} from "vuex";
import {GET_ALL_TENANT} from "@/core/services/store/mtenant.module";

export default {
    components: {
        Dialog
    },
    data() {
        return {
            ruleForm: {
                form_name: '',
                form_url: '',
                item_group_names: '',
                parent_form_id:'',
                approval_id:'',
                item_group_name_middle:'',
                copy_flag: false,
                copy_group_name: false,
                copy_group_name_middle: false,
                kaiso_flg: false,
                henshu_flg: false,
                tenant_copy_flg: false,
                tenant_code: '',
                display_new_account: false,
                special_item: false,
                branch_condition: false,
                auto_calculation: false,
            },
            disabled_check: false,
            form_base_select:[],
            listTenant:[],
            list_approval:[],
            list_approval_all:[],
            constants: constants,
            loading: false,
            isSubmit: false,
            not_permisson_popup: false
        }
    },
    computed: {
        ...mapGetters(["currentUser"]),
    },
    async created() {
        this.getListFormBases();
        this.getListApproval();
        if (this.currentUser.super_user){
            this.listTenant = await this.getAllTenant();
        }
        if (!this.hasPermissonCheck('form_flg')){
            this.not_permisson_popup = true
        }
    },
    watch:{
      list_approval_all(newData){
        this.changeTenant();
      },
    },
    methods: {
        ...mapActions([GET_ALL_TENANT]),
        getListApproval() {
            this.$store.dispatch(GET_LIST_APPROVAL_ROLE).then(data => {
                if (data.code == constants.STATUS_CODE.SUCCESS) {
                    this.list_approval_all = data.data.map(approvalList => {
                        return {
                            approval_kaiso: approvalList.approval_kaiso,
                            tenant_code: approvalList.tenant_code,
                            label: approvalList.approval_name,
                            value: approvalList.approval_id
                        }
                    });
                    this.list_approval = this.list_approval_all;
                }
            }).catch(e => {
                this.notifyError(e)
            })
        },
        getListFormBases() {
            this.loading = true
             this.$store.dispatch(GET_ALL_LIST_FORM_BASE).then(data => {
                this.loading = false
                if (data && data.data) {
                    if (data.code === constants.STATUS_CODE.SUCCESS) {
                        this.form_base_select = data.data.map(formBase => {
                            return {
                                label: formBase.form_name,
                                value: formBase.form_id
                            }
                        })
                    } else {
                        this.notifyError(data.message)
                    }
                } else {
                    this.notifyError(this.$t("ERROR_SERVER"))
                }
            }).catch(e => {
                this.loading = false
                this.notifyError(e)
            })
            this.loading = false

        },
        create() {
            this.isSubmit = false
            this.loading = true
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.turnOffErrorMessage('ruleForm')
                    this.$store.dispatch(CREATE_FORM_BASE, this.ruleForm).then(data => {
                        this.loading = false
                        if (data && data.data) {
                            if (data.code === constants.STATUS_CODE.SUCCESS) {
                                this.notifySuccess(this.$t('FORM.SETTING.SUCCESS', {form_name: this.ruleForm.form_name}))
                                setTimeout(()=>{
                                    this.$router.push({name: "form-base-list"})
                                }, 200);
                                return;
                            }
                            if (data.code === constants.STATUS_CODE.BAD_REQUEST) {
                                this.showErrorMessage(data.data, 'ruleForm')
                            } else {
                                this.notifyError(data.message)
                            }
                        } else {
                            this.notifyError('error server')
                        }
                    }).catch(e => {
                        this.loading = false
                        this.notifyError(e)
                    })
                } else {
                    this.loading = false
                }
            });
        },
        changeApproval(){
            let findCurrentApproval = this.list_approval.find(data => this.ruleForm.approval_id === data.value);
        },
        changeTenant(){
            this.ruleForm.approval_id = '';
            this.list_approval = this.list_approval_all.filter(data => {
                if (data.tenant_code === this.ruleForm.tenant_code){
                    return true;
                }else if((this.ruleForm.tenant_code === ' ' || !this.ruleForm.tenant_code) && !data.tenant_code){
                    return true;
                } else if (this.currentUser.tenant && this.currentUser.tenant.tenant_code === data.tenant_code){
                    return true;
                }
                return false;
            });
        },
    },
}
</script>
